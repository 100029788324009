<template>
  <v-tooltip
    :color="color"
    style="z-index:5"
    top
  >
    <template #activator="{ on }">
      <span v-on="on">
        <v-icon
          :key="icon"
          :class="{ 'ml-1': index > 0 }"
          :small="!$vuetify.breakpoint.mobile"
          :color="color"
        >
          {{ icon }}
        </v-icon>
      </span>
    </template>
    {{ text }}
    <ul v-if="iconKey == 'pregnant' && pregnant && (drug.category >= 'C' || drug.category == '?')">
      <li>
        Category {{ drug.category >= 'C' ? drug.category : 'unassigned' }}
      </li>
    </ul>
    <ul v-if="iconKey == 'allergies' && drug_allergies.includes(drug.class_id)">
      <li
        v-for="allergy, i in allergies.filter(x => x.group.drug_classes.map(x => x.id).includes(drug.class_id)).map(x => x.name)"
        :key="i"
      >
        {{ allergy }}
      </li>
    </ul>
    <ul v-if="iconKey == 'resistance' && resistances">
      <li
        v-for="resistance, i in resistances"
        :key="i"
      >
        {{ resistance.name[$i18n.locale] || resistance.name.en }}
        <div v-if="nihData[organism.name][resistance.name.en] && nihData[organism.name][resistance.name.en].frequency_class">
          <i>
            {{
              $t('meds_matrix.nih', {
                resistance: resistance.short_name ?? resistance.name[$i18n.locale],
                percentage: $options.filters.percentage(nihData[organism.name][resistance.name.en].frequency_class),
                total: $options.filters.numeral(nihData[organism.name][resistance.name.en].total),
                organism: organism.name
              })
            }}
          </i>
        </div>
      </li>
    </ul>
  </v-tooltip>
</template>
<script>
export default {
  props: {
    iconKey: {
      type: String,
      default: null,
    },
    organism: {
      type: Object,
      default: () => {},
    },
    drug: {
      type: Object,
      default: () => {},
    },
    allergies: {
      type: Array,
      default: () => ([]),
    },
    resistanceNames: {
      type: Array,
      default: () => ([]),
    },
    drugResistance: {
      type: Array,
      default: null,
    },
    pediatric: Boolean,
    pregnant: Boolean,
    text: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: 'far fa-minus',
    },
    index: {
      type: Number,
      default: 0,
    },
    nihData: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    drug_allergies () {
      return this.allergies.filter(x => x.group).flatMap(x => x.group.drug_classes).map(x => x.id)
    },
    resistances () {
      return this.organism.relevant_resistances
        .filter((v, i, a) => a.findIndex(v2 => (v2.name.en === v.name.en)) === i)
        .filter(value => this.resistanceNames.includes(value.name.en))
        .filter(value => this.drug.resistance.map(r => r.name.en).includes(value.name.en))
    },
  },
}
</script>
